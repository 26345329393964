<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<script>
export default {  
}
</script>
<style>
body{
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
</style>