// import router from '@/router';
import axios from "axios";
import { useUserStore } from "@/stores/UserStore";
import router from "@/router";
export const appAxios = axios.create({
  baseURL: "http://localhost:3000",
});
export const bamsAxios = axios.create({
  baseURL: "https://api.quatrobilisim.com/api",
});
export const soapAxios = axios.create({
  baseURL:
    "https://osb-mwwebgate.ibb.gov.tr/External/TopluUlasim/TopluUlasimService/ProxyService/TopluUlasimServiceSOAPPS?wsdl",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    "Access-Control-Allow-Headers": "Content-Type",
    "Content-Type": "text/xml",
  },
});
export const apiAxiosQuatro = axios.create({
  baseURL: "https://api.quatrobilisim.com/api",
});
export const nodeApiAxiosQuatro = axios.create({
  baseURL: "https://telemetri.quatrobilisim.com",
});
export const tariffServiceApiAxiosQuatro = axios.create({
  baseURL: "https://quatro-tariff-service.quatrobilisim.com",
});
nodeApiAxiosQuatro.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const userStore = useUserStore();
        await userStore.logout();
        router.replace("/login");
      }
    }
    return Promise.reject(error);
  }
);
apiAxiosQuatro.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const userStore = useUserStore();
        await userStore.logout();
        router.replace("/login");
      }
    }
    return Promise.reject(error);
  }
);
