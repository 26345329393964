<template>
  <v-footer border elevation="15">
    <v-row justify="space-between" align="center">
      <v-col cols="auto" align-self="start">
        Copyright &copy; 2024 <span class="text-info">Quatro</span>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-img cover height="30" :src="footerLogo"></v-img>
      </v-col>
      <v-col cols="auto" align-self="end">
        <span style="font-weight: 600">Quatro Bilişim</span> adına
        <a href="http://mobilulasim.com" class="ahover" target="_blank"
          >İpos Mobil Ulaşım Teknolojileri
        </a>
        tarafından geliştirilmiştir.
        <span class="text-dark font-weight-bold">Version </span> 0.0.1
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import footerLogo from "../../../public/assets/img/quatro_black_logo.svg";
export default {
  data() {
    return {
      footerLogo,
    };
  },
};
</script>
<style scoped>
.ahover {
  font-weight: 500;
  color: #0992e8;
  text-decoration: none;
}
.ahover:hover {
  color: rgba(11, 59, 148, 0.753);
}
.v-footer {
  min-height: 6vmin !important;
}
.v-row {
  height: 100%;
}
.v-col {
  height: 100%;
}
</style>
