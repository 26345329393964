import { appAxios, apiAxiosQuatro } from './appAxios';
export const getUsers = async () => {
    try {
        const response = await appAxios.get('/users');
        if (!response) return { message: "Kullanıcı Yok!" };
        return response.data
    } catch (error) {
        console.log(error?.message);
    }
};
export const login = async (mail, password) => {
    try {
        const body = {
            email: mail,
            password: password
        }
        const { data, status, statusText } = await apiAxiosQuatro.post(`/Login/Login`, body);
        if (!data) return { status: false, statusCode: status, message: statusText, data: null }
        return {status: true, statusCode: status, message: statusText, data: data};
    } catch (error) {
        return {status: false, statusCode: error?.response?.status, message: error?.message, data: null}
    }
};
export const loginApi = async (mail, password) => {
    try {
        const body = {
            email: mail,
            password: password
        }
        const { data, status, statusText } = await apiAxiosQuatro.post(`/Login/Login`, body);
        if (!data) return { status: false, statusCode: status, message: statusText, data: null }
        return {status: true, statusCode: status, message: statusText, data: data};
    } catch (error) {
        return {status: false, statusCode: error?.response?.status, message: error?.message, data: null}
    }
};
export const getPagesByPathGetPagesByPathService = async (token, path) => {
    try {
        const { data, status, statusText } = await apiAxiosQuatro.get(`/Pages/GetPagesByPath?Path=${path}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(!data) return { status: false, statusCode: status, message: statusText, data: null }
        return { status: true, statusCode: status, message: statusText, data }
    } catch (error) {
        return {status: false, statusCode: error?.response?.status || 500, message: error?.response?.data?.error?.message || error?.message, data: null}
    }
};
export const checkUserAuthLevelService = async (token, body) => {
    try {
        const { data, status, statusText } = await apiAxiosQuatro.post(`/Auth/CheckUserAuthLevel`, body, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(!data) return { status: false, statusCode: status, message: statusText, data: null }
        return { status: true, statusCode: status, message: statusText, data }
    } catch (error) {
        return {status: false, statusCode: error?.response?.status || 500, message: error?.response?.data?.error?.message || error?.message, data: null}
    }
};
export const getAllUserAuthsService = async (token) => {
    try {
        const { data, status, statusText } = await apiAxiosQuatro.get(`/Auth/GetAllUserAuths`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(!data) return { status: false, statusCode: status, message: statusText, data: null }
        return { status: true, statusCode: status, message: statusText, data }
    } catch (error) {
        return {status: false, statusCode: error?.response?.status || 500, message: error?.response?.data?.error?.message || error?.message, data: null}
    }
};