import { defineStore } from "pinia";
import { loginApi, getPagesByPathGetPagesByPathService, checkUserAuthLevelService, getAllUserAuthsService } from '../utils/UserService';
export const useUserStore = defineStore('userStore', {
    state: () => ({
        userInfo: null,
        users: null,
        auth: false,
        token: null,
        allUserAuth: []
    }),
    getters: {
        isAuthenticated: (state) => state.auth,
        getUserInfo: (state) => state.userInfo,
        getToken: (state) => state.token,
        getAllUserAuth: (state) => state.allUserAuth,
    },
    actions: {
        async login(mail, password) {
            const response = await loginApi(mail, password);
            if (response?.status) {
                this.auth = true;
                this.token = response?.data?.token;
                delete response?.data?.token;
                this.userInfo = response?.data;
                return { message: "Giriş Başarılı!", status: true }
            } else {
                this.auth = false;
                this.token = null;
                this.userInfo = null;
                return { message: "Giriş Başarısız!", status: false }
            }            
        },
        async getPagesByPathAction(path) {
            const response = await getPagesByPathGetPagesByPathService(this.token, path);
            return response;
        },
        async checkUserAuthLevelAction(body) {
            const response = await checkUserAuthLevelService(this.token, body);
            return response;
        },
        async getAllUserAuthsAction() {
            const response = await getAllUserAuthsService(this.token);
            if (response.statusCode === 200) this.allUserAuth = response.data;
            else this.allUserAuth = [];
            return response;
        },
        async logout() {
            try {
                this.userInfo = null;
                this.auth = false;
                this.token = null;
                return { message: "Çıkış Başarılı!", status: true }
            } catch (error) {
                return {message: error?.message, status: false};
            }
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage
            },
        ],
    }
});