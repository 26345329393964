<template>
  <sidebar-comp
    :user="user"
    :title="routeName"
    :menuItems="menuItems.menuItems"
  ></sidebar-comp>
  <v-main>
    <v-container fluid>
      <slot />
    </v-container>
    <footer-comp></footer-comp>
  </v-main>
</template>
<script>
import { mapState, mapActions } from "pinia";
import { useUserStore } from "../../stores/UserStore";
import SidebarComp from "./SidebarComp.vue";
import FooterComp from "./FooterComp.vue";
export default {
  components: {
    SidebarComp,
    FooterComp,
  },
  computed: {
    ...mapState(useUserStore, ["getUserInfo", "getAllUserAuth"]),
  },
  async created() {
    const menuArray = [
      {
        title: "Yönetim Paneli",
        icon: "mdi-cogs",
        route: "#",
        child: [
          { title: "Rol Yönetimi", icon: "mdi-account", route: "/#/roles" },
          {
            title: "Kullanıcı Yönetimi",
            icon: "mdi-account",
            route: "/#/users",
          },
          { title: "Sayfa Yönetimi", icon: "mdi-account", route: "/#/pages" },
        ],
      },
      {
        title: "Bayi Dashboard",
        icon: "mdi-store-outline",
        route: "/#/dealer/dashboard",
      },
      {
        title: "Bayi Durakları Listesi",
        icon: "mdi-store-marker-outline",
        route: "/#/dealer_stations",
      },
      { title: "Bayiler", icon: "mdi-store-outline", route: "/#/dealers" },
      { title: "Duraklar", icon: "mdi-store-24-hour", route: "/#/stations" },
      { title: "Araçlar", icon: "mdi-taxi", route: "/#/vehicles" },
      { title: "Cihazlar", icon: "mdi-devices", route: "/#/devices" },
      {
        title: "Sürücüler",
        icon: "mdi-card-account-details",
        route: "/#/drivers",
      },
      { title: "Araç Sahibi", icon: "mdi-account-circle", route: "/#/owners" },
      {
        title: "Tarife Paneli",
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: "Tarife Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/tariffs",
          },
        ],
      },
      {
        title: "Tarife Servisi",
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: "Tarife Geçmişi Listeleme",
            icon: "mdi-list-box-outline",
            route: "/#/tariff_histories",
          },
          {
            title: "Tarife Tanımlama",
            icon: "mdi-playlist-plus",
            route: "/#/tariff_match",
          },
        ],
      },
      {
        title: "Envanter",
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: "Envanter Ölçü Birimi Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/inventory_measurements",
          },
          {
            title: "Envanter Durumu Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/inventory_status",
          },
          {
            title: "Envanter Ürün Türü Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/inventory_products",
          },
          {
            title: "Envanter Marka Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/inventory_brands",
          },
          {
            title: "Envanter Stok Kartı Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/inventory_card_stocks",
          },
          {
            title: "Envanter Listesi",
            icon: "mdi-list-box-outline",
            route: "/#/inventories",
          },
        ],
      },
      {
        title: "Servis İşlemleri",
        icon: "mdi-cogs",
        route: "#",
        child: [
          {
            title: "Servis Kaydı Oluştur",
            icon: "mdi-list-box-outline",
            route: "/#/services/new_service_record",
          },
          {
            title: "Bekleyen Servis İşlemleri",
            icon: "mdi-list-box-outline",
            route: "/#/services/waiting_processes",
          },
          {
            title: "Tamamlanan Servis İşlemleri",
            icon: "mdi-list-box-outline",
            route: "/#/services/completed_processes",
          },
        ],
      },
      // { title: 'QR Bağlantı Listesi', icon: 'mdi-qrcode-scan', route: '/#/' },
      // { title: 'Mesaj Gönder', icon: 'mdi-message-text-fast-outline', route: '/#/' },
    ];

    this.user.fullName = this.getUserInfo.name + " " + this.getUserInfo.surname;
    await this.getAllUserAuthsAction();
    for (const i of menuArray) {
      if (i.child) {
        const childArray = [];
        for (const y of i.child) {
          const response = this.getAllUserAuth.find((t) => {
            if (t.page.path === y.route.slice(2)) return y;
          });
          if (response) {
            if (response?.permissions.permissionName !== "Unauthorized") {
              childArray.push(y);
            }
          }
        }
        if (childArray.length > 0) {
          this.menuItems.menuItems.push(i);
          i.child = childArray;
        }
      } else {
        const response = this.getAllUserAuth.find((t) => {
          if (t.page.path === i.route.slice(2)) return t;
        });
        if (response) {
          if (response?.permissions.permissionName !== "Unauthorized") {
            this.menuItems.menuItems.push(i);
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(useUserStore, ["getAllUserAuthsAction"]),
  },
  data() {
    return {
      routeName: this.$route.name,
      user: {
        fullName: null,
      },
      menuItems: {
        menuItems: [],
      },
    };
  },
};
</script>
<style>
body {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.v-main {
  background-color: #e9ecef;
  min-height: 87vh !important;
}
.v-container {
  min-height: 87vh;
}
</style>
